<template>
    <div id="stores-index">
        <!-- boton nueva tienda -->
        <button
            :class=" route === 'app-stores-index' ? 'vs-component vs-button rounded-full bg-primary text-white vs-button-filled' : 'vs-component vs-button rounded-full bg-white text-primary vs-button-filled'"
            @click="toogleRoute( '/app/stores/new/form' )"
            v-if="userCreateShops"
        >
            <feather-icon icon="PlusIcon" class="inline-block align-middle"></feather-icon>
            {{ this.$t('Nueva Tienda') }}
        </button>

        <!-- <button style="margin-left:10px; height:48px;"
            :class=" route === 'app-stores-index' ? 'vs-component vs-button rounded-full bg-primary text-white vs-button-filled' : 'vs-component vs-button rounded-full bg-white text-primary vs-button-filled'"
            @click="toogleRoute( '/app/stores/new/massive' )"
            v-if="userCreateShops"
        >
            <img :src="require(`@/assets/icons/CloudUploadIcon.svg`)" width="25px" class="inline-block align-middle mr-2" />
                        
            {{ this.$t('Cargar Tiendas') }}
        </button> -->

        <!-- boton Filtro de busqueda -->
        <!-- <button
            type="button"
            class="vs-component vs-button vs-button-relief rounded-full lg:ml-5 xs:mt-2"
            :style=" showSearchFilters ? `background-color: #666; color: #FFF;` : `background-color: #FFF; color: #666;`"
            @click="showSearchFilters = !showSearchFilters"
        >
            <feather-icon icon="SlidersIcon" class="inline-block align-middle transform rotate-90"></feather-icon>&nbsp;
            Filtros de Búsqueda
        </button> -->

        <!-- Enlace carga de zonas -->
        <!-- <button
            type="button"
            class="vs-component bg-info text-white vs-button vs-button-relief rounded-full lg:ml-5 lg:float-right xs:mt-2"
            :class="{'invisible' : route === 'app-stores-upload-zones'}"
            style="background-color: #FFF; color: #666;"
            @click="toogleRoute( '/app/stores/uploads/zones' )"
        >
            <feather-icon icon="UploadCloudIcon" class="inline-block align-middle mr-2"></feather-icon>
            {{ this.$t('Cargar zonas de tiendas') }}
        </button> -->

        <!-- Formulario de busqueda -->
        <!-- <div
            class="w-1/4 inline-flex align-middle rounded-full float-right pt-2 pl-2"
            style="background-color: #CCC; color: #666;"
        >
            <feather-icon icon="SearchIcon" class="inline-block align-middle"></feather-icon>&nbsp;
            <input
                id="search-icon"
                type="text"
                class="w-11/12 inline-block align-middle bg-transparent border-0 mb-2"
                placeholder=""
                autofocus
            />
        </div> -->

        <search-filters v-if="showSearchFilters" class="mt-10"></search-filters>

        <router-view class="mt-10"></router-view>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';

//import searchFilters from './SearchFilters.vue';
import auth          from '../../../auth/authService';
import Cookies from 'js-cookie';

export default Vue.extend({
    data() {
        return {
            showSearchFilters: false,
            auth,
            cancel: false
        }
    },

    watch : {
        showSearchFilters() {
            this.$store.dispatch( 'stores/getAllStores' );
        }
    },

    created() {
        const userInfo = JSON.parse(Cookies.get('userInfo'));
        if (userInfo.auth_grants.role == "USERS_SELLER"){
            this.$router.push( {path: '/app/dashboard'});
        }
        if( this.route != 'app-stores-index' ) {
            this.cancel = true
        }
        this.$store.dispatch( 'updatePageTitle', {
            title: this.$t('Sellers'),
            subtitle: this.$t('crea y administra los sellers'),
        });
        
        this.$store.dispatch( 'stores/getAllStoreCategories' );
    },

    methods:{
        toogleRoute( path ) {
            if( this.route === 'app-stores-index' ) {
                this.cancel = true
                this.$router.push( { path });
            } else {
                this.cancel = false
                this.$router.push( {path: '/app/stores'});
            }
        },

    },

    computed: {
        route() {
            return this.$route.name;
        },
        
        userCreateShops() {
            const isValid = this.auth.isValidModule('CREATE_SHOPS, MANAGE_SHOPS');
            return isValid;
            
        },
    },

    components: {
        //searchFilters,
    },

})
</script>
<style lang="scss">
.imgs {
    filter: hue-rotate(60deg) brightness(500%);
  }
  
</style>